module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mate Portfolio","short_name":"Mate","start_url":"/","background_color":"#FFFFFF","theme_color":"#7c37ad","display":"minimal-ui","icon":"icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bd811806cc1b8afc275a716b064f3106"},
    },{
      plugin: require('../node_modules/gatsby-theme-mate/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"aSmd4Jbj_YPDijQY3g8oEl5GOj5EeVIxyXASxb-dgq0","spaceId":"7e0uqlmy2f6d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-199307254-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    }]
